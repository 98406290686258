import { logEvent } from 'firebase/analytics';
import { analytics } from '../utilities/firebase';

export type CandidatePageTracking = {
  pageView: boolean;
};

export function trackCandidateFormPageView() {
  // Log the page view event with the custom ID
  logEvent(analytics, 'candidate_form_page_view', {
    custom_id: window.location.href.split('/').pop(),
  });
}

export function trackCandidateFormApplication() {
  // Log the page view event with the custom ID
  logEvent(analytics, 'candidate_submission', {
    custom_id: window.location.href.split('/').pop(),
  });
  window.location.href = 'https://www.psychowork.pl/success';
}
