import { createBrowserRouter } from 'react-router-dom';
import { CandidatePage } from '../candidate';

import { JobPage } from '../job';

export const router = createBrowserRouter([
  {
    path: '/candidate/:jobSlug',
    element: <CandidatePage />,
  },
  {
    path: '/register',
    element: <CandidatePage />,
  },
  {
    path: '/job',
    element: <JobPage />,
  },
  {
    path: '/',
    element: <JobPage />,
  },
]);
