import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiFile } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FileUpload } from '../utilities/fileUpload';
import { candidateUrl, infoUrl, storage } from '../utilities/firebase';
import Footer from '../utilities/footer';
import {
  CandidatePageTracking,
  trackCandidateFormApplication,
  trackCandidateFormPageView,
} from './tracking';

export function CandidatePage() {
  const { jobSlug } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [offerDetails, setOfferDetails] = useState({
    loading: true,
    companyName: '',
    companyLogo: '',
    companyCover: '',
    offerSalary: '',
    offerTitle: '',
  });
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors, isSubmitting },
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    criteriaMode: 'all',
  });

  const checkBoxList = [
    watch('acceptanceTerms'),
    watch('acceptancePrivacyPolicy'),
    watch('acceptanceNewsletter'),
  ];
  const allChecked = checkBoxList.every(Boolean);
  const isIndeterminate = checkBoxList.some(Boolean) && !allChecked;

  const tracked = useRef<CandidatePageTracking>({
    pageView: false,
  });
  useEffect(() => {
    if (!tracked.current.pageView) {
      trackCandidateFormPageView();
      tracked.current.pageView = true;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.post(
        infoUrl,
        { id: jobSlug },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setOfferDetails({
        loading: false,
        companyName: data.companyName,
        companyLogo: data.companyLogo,
        companyCover: data.companyCover,
        offerSalary: data.offerSalary,
        offerTitle: data.offerTitle,
      });
    };

    fetchData();
  }, [jobSlug]);

  const validateFiles = (value: any) => {
    if (!value.length) return 'Conajmniej jeden plik jest wymagany.';
    if (value.length > 3) return 'Możesz tylko załączyć 3 pliki.';
    for (const file of Array.from(value)) {
      if ((file as any).size > 1024 * 1024 * 5)
        return 'Pojedynczy plik może mieć maksymalnie 5MB.';
    }
    clearErrors('file_');
    return true;
  };

  const validateCheckbox = (value: any) => {
    if (value === true) {
      return true;
    } else {
      return 'Pole wymagane';
    }
  };

  const uploadAllFiles = async (candidateUniqueId: string) => {
    const uploadPromises = selectedFiles.map((file: any, index: any) => {
      return new Promise((resolve, reject) => {
        if (file.progress === 100) {
          resolve(true);
        }
        const storageRef = ref(
          storage,
          `/candidate/uploads/${candidateUniqueId}/${file.name}`
        );
        const metadata = {
          contentType: file.contentType,
        };
        const uploadTask = uploadBytesResumable(
          storageRef,
          file.file,
          metadata
        );
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Calculate progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // Update progress in state
            setSelectedFiles((prevFiles: any) =>
              prevFiles.map((f: any, i: any) => {
                if (i === index) {
                  // Identify the file by its index
                  return { ...f, progress }; // Update progress
                }
                return f; // Leave other files unchanged
              })
            );

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error('File failed', candidateUniqueId, error);
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            setSelectedFiles((prevFiles: any) =>
              prevFiles.map((f: any, i: any) => {
                if (i === index) {
                  // Identify the file by its index
                  return { ...f, progress: 100 }; // Update progress
                }
                return f; // Leave other files unchanged
              })
            );
            console.log('File successfully uploaded', candidateUniqueId);
            // Optionally, you might want to update the state to reflect that the upload is complete
            resolve(true);
          }
        );
      });
    });
    return await Promise.all(uploadPromises);
  };

  const onSubmit = async (values: any) => {
    try {
      let allFilesValid = true;
      selectedFiles.forEach((file: any) => {
        if (file.error) {
          allFilesValid = false;
          console.error(`Error with file ${file.name}: ${file.error}`);
        }
      });

      if (!allFilesValid) {
        console.error(`One or more files are not valid.`);
        return;
      }

      const candidateUniqueId = uuidv4();
      await uploadAllFiles(candidateUniqueId);

      const files = (selectedFiles as any).map((file: any) => ({
        name: file.name,
        type: file.contentType,
        size: file.size,
      }));
      await axios.post(
        candidateUrl,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: '+48' + values.phone,
          jobOfferId: jobSlug,
          candidateUniqueId,
          acceptanceTerms: values.acceptanceTerms,
          acceptancePrivacyPolicy: values.acceptancePrivacyPolicy,
          acceptanceNewsletter: values.acceptanceNewsletter,
          files,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      trackCandidateFormApplication();
      return true;
    }
  };

  return (
    <>
      <Center mt="20px" mb="20px">
        <Box
          bg="background.100"
          borderWidth="1px"
          borderRadius="20px"
          borderColor={'#e4e4ed'}
          position={'relative'}
          width="550px"
          m={2}
        >
          <Box position={'relative'}>
            <Box
              position={'absolute'}
              width="200px"
              left="50%"
              top="10px"
              borderRadius={'5px'}
              p="15px"
              backgroundColor="white"
              borderColor={'#e4e4ed'}
              zIndex={4}
              transform="translateX(-50%)"
            >
              <Image src="https://assets-global.website-files.com/659e5bc66e67b3c9d3f8178c/65fc8406c407058d27699fd0_logo.svg" />
            </Box>
            <Skeleton isLoaded={!offerDetails.loading}>
              <Box>
                <Image
                  filter="auto"
                  blur="2px"
                  h="250px"
                  width="600px"
                  borderTopLeftRadius={'20px'}
                  borderTopRightRadius={'20px'}
                  objectFit={'cover'}
                  src={offerDetails.companyCover}
                />
              </Box>
            </Skeleton>

            <Skeleton isLoaded={!offerDetails.loading}>
              <Box
                position={'absolute'}
                width="125px"
                height="125px"
                borderRadius={'5px'}
                border={'1px solid'}
                borderColor={'#e4e4ed'}
                left="50%"
                bottom="-62.5px"
                p="15px"
                backgroundColor="white"
                zIndex={4}
                display="flex"
                transform="translateX(-50%)"
              >
                <Image width="100%" src={offerDetails.companyLogo} />
              </Box>
            </Skeleton>
          </Box>
          <VStack spacing="5" p="4" mt="80px" maxW={600}>
            <Text fontSize="sm">
              Aby przesłać swoje zgłoszenie, wypełnij formularz poniżej.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel htmlFor="firstName" color="text.500" mb="20px">
                  Imię
                </FormLabel>
                <Input
                  id="firstName"
                  placeholder="Wpisz swoje imię"
                  {...register('firstName', {
                    required: 'To pole jest wymagane',
                  })}
                  bg="white"
                />
                <FormErrorMessage color="red.500">
                  {errors.firstName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel
                  htmlFor="lastName"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Nazwisko
                </FormLabel>
                <Input
                  id="lastName"
                  placeholder="Wpisz swoje nazwisko"
                  {...register('lastName', {
                    required: 'To pole jest wymagane',
                  })}
                />
                <FormErrorMessage>
                  {errors.lastName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" color="text.500" mb="10px" mt="20px">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  placeholder="Email"
                  {...register('email', {
                    required: 'To pole jest wymagane',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.phone}>
                <FormLabel htmlFor="phone" color="text.500" mb="10px" mt="20px">
                  Numer telefonu
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>+48</InputLeftAddon>
                  <Input
                    id="phone"
                    placeholder="Wpisz swój numer telefonu"
                    {...register('phone', {
                      required: 'To pole jest wymagane',
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.phone?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.file_}>
                <FormLabel htmlFor="file" color="text.500" mb="10px" mt="20px">
                  Dodaj swoje CV
                </FormLabel>

                <FileUpload
                  valueName={'file_'}
                  setError={setError}
                  setValue={setValue}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  accept={'image/*,.pdf'}
                  multiple
                  register={register('file_', { validate: validateFiles })}
                >
                  <Button leftIcon={<Icon as={FiFile} />} fontWeight="500">
                    Załącz pliki
                  </Button>
                </FileUpload>
                <FormErrorMessage>
                  {errors.file_?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <FormControl as="fieldset" mt="20px" mb="20px">
                <FormLabel color="text.500">Zgody</FormLabel>
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) => {
                    setValue('acceptanceTerms', !allChecked);
                    setValue('acceptancePrivacyPolicy', !allChecked);
                    setValue('acceptanceNewsletter', !allChecked);
                    trigger('acceptanceTerms');
                    trigger('acceptancePrivacyPolicy');
                  }}
                >
                  Zaznacz wszystkie
                </Checkbox>
                <Stack pl={6} mt={1} spacing={1}>
                  <Checkbox
                    {...register('acceptanceTerms', {
                      validate: validateCheckbox,
                    })}
                    isChecked={watch('acceptanceTerms')}
                    size="sm"
                  >
                    * Akceptuję{' '}
                    <Link
                      href="https://docs.google.com/document/d/e/2PACX-1vTOC9rhO8xyWzNjILxaM7Hv13DizXm70nxfaSz1ZZVeJKyLBEIL_gQtRJBJNUHnZM9eU6HAFV1wMLLq/pub"
                      isExternal
                      target="_blank"
                      color="teal.500"
                    >
                      regulamin
                    </Link>{' '}
                    serwisu PsychoWork.pl
                  </Checkbox>
                  <Text color="red" fontSize="xs">
                    {errors.acceptanceTerms?.message?.toString()}
                  </Text>
                  <Checkbox
                    {...register('acceptancePrivacyPolicy', {
                      validate: validateCheckbox,
                    })}
                    isChecked={watch('acceptancePrivacyPolicy')}
                    size="sm"
                  >
                    * Akceptuję{' '}
                    <Link
                      href="https://docs.google.com/document/d/e/2PACX-1vRUpg9Sc0Vq8TmiTI_ouTcA2v2Zjf1HP29kSY8SqDFHRlfo58HLweGerMjAdDjBYN2ct6I75RxL8lWL/pub"
                      isExternal
                      target="_blank"
                      color="teal.500"
                    >
                      politykę prywatności
                    </Link>{' '}
                    serwisu PsychoWork.pl
                  </Checkbox>
                  <Text color="red" fontSize="xs">
                    {errors.acceptancePrivacyPolicy?.message?.toString()}
                  </Text>
                  <Checkbox
                    {...register('acceptanceNewsletter')}
                    size="sm"
                    isChecked={watch('acceptanceNewsletter')}
                  >
                    Zapisz mnie do newslettera
                  </Checkbox>
                </Stack>
              </FormControl>
              <Divider mt="10px" />
              <Center mt="30px" mb="30px">
                <Button
                  mt={4}
                  backgroundColor="#69acdf"
                  fontWeight={'600'}
                  lineHeight={'1.125em'}
                  borderRadius={'1000px'}
                  color="white"
                  isLoading={isSubmitting}
                  loadingText="Wysyłamy Twoje zgłoszenie"
                  type="submit"
                >
                  Wyślij zgłoszenie
                </Button>
              </Center>
            </form>
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
}
