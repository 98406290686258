import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiFile } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FileUpload } from '../utilities/fileUpload';
import { jobUrl, storage } from '../utilities/firebase';
import Footer from '../utilities/footer';
import {
  JobPageTracking,
  trackJobFormPageView,
  trackJobFormSubmission,
} from './tracking';

const RedAsterisk = () => (
  <Text color="red" style={{ display: 'inline' }}>
    *
  </Text>
);

type JobPackageOption = 'basic' | 'premium' | 'free';

const jobPackages: Record<JobPackageOption, string> = {
  free: '0. Ogłoszenie darmowe - 0 PLN (netto)',
  basic: '1. Ogłoszenie basic - 99.00 PLN (netto)',
  premium: '2. Ogłoszenie premium - 199 PLN (netto)',
};

export function JobPage() {
  const [selectedFilesLogo, setSelectedFilesLogo] = useState([]);
  const [selectedFilesCover, setSelectedFilesCover] = useState([]);

  const tracked = useRef<JobPageTracking>({
    pageView: false,
  });
  useEffect(() => {
    if (!tracked.current.pageView) {
      trackJobFormPageView();
      tracked.current.pageView = true;
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    clearErrors,
    trigger,
    watch,
  } = useForm({
    criteriaMode: 'all',
  });

  const [searchParams] = useSearchParams();

  const selectedPricing = searchParams.get(
    'pricing'
  ) as JobPackageOption | null;

  useEffect(() => {
    if (selectedPricing && jobPackages[selectedPricing] !== undefined) {
      setValue('package', jobPackages[selectedPricing]);
    }
  }, [selectedPricing, setValue]);

  const checkBoxList = [
    watch('acceptanceTerms'),
    watch('acceptancePrivacyPolicy'),
    watch('acceptanceNewsletter'),
  ];
  const allChecked = checkBoxList.every(Boolean);
  const isIndeterminate = checkBoxList.some(Boolean) && !allChecked;

  const validateFiles = (value: any) => {
    if (!value.length) return 'Conajmniej jeden plik jest wymagany.';
    if (value.length > 3) return 'Możesz tylko załączyć 3 pliki.';
    for (const file of Array.from(value)) {
      if ((file as any).size > 1024 * 1024 * 5)
        return 'Pojedynczy plik może mieć maksymalnie 5MB.';
    }
    clearErrors('fileLogo');
    clearErrors('fileCover');
    return true;
  };

  const uploadAllFiles = async (
    jobUniqueId: string,
    files: any,
    setFiles: any
  ) => {
    const uploadPromises = files.map((file: any, index: any) => {
      return new Promise((resolve, reject) => {
        if (file.progress === 100) {
          resolve(true);
        }
        const storageRef = ref(
          storage,
          `/job/uploads/${jobUniqueId}/${file.name}`
        );
        const metadata = {
          contentType: file.contentType,
        };
        const uploadTask = uploadBytesResumable(
          storageRef,
          file.file,
          metadata
        );
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Calculate progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // Update progress in state
            setFiles((prevFiles: any) =>
              prevFiles.map((f: any, i: any) => {
                if (i === index) {
                  // Identify the file by its index
                  return { ...f, progress }; // Update progress
                }
                return f; // Leave other files unchanged
              })
            );

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error('File failed', jobUniqueId, error);

            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            setFiles((prevFiles: any) =>
              prevFiles.map((f: any, i: any) => {
                if (i === index) {
                  // Identify the file by its index
                  return { ...f, progress: 100 }; // Update progress
                }
                return f; // Leave other files unchanged
              })
            );
            console.log('File successfully uploaded', jobUniqueId);
            // Optionally, you might want to update the state to reflect that the upload is complete
            resolve(true);
          }
        );
      });
    });
    return Promise.all(uploadPromises);
  };

  const validateCheckbox = (value: any) => {
    if (value === true) {
      return true;
    } else {
      return 'Pole wymagane';
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let allFilesValid = true;

      const allSelectedFiles = [...selectedFilesLogo, ...selectedFilesCover];
      allSelectedFiles.forEach((file: any) => {
        if (file.error) {
          allFilesValid = false;
          console.error(`Error with file ${file.name}: ${file.error}`);
        }
      });

      if (!allFilesValid) {
        console.error(`One or more files are not valid.`);
        return;
      }

      const jobUniqueId = uuidv4();
      await uploadAllFiles(
        jobUniqueId,
        selectedFilesLogo,
        setSelectedFilesLogo
      );
      await uploadAllFiles(
        jobUniqueId,
        selectedFilesCover,
        setSelectedFilesCover
      );

      const files = (allSelectedFiles as any).map((file: any) => ({
        name: file.name,
        type: file.contentType,
        size: file.size,
      }));

      const payload = {
        jobUniqueId,
        files,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: '+48' + values.phone,
        specialization: values.specialization,
        experience: values.experience,
        voivodeship: values.voivodeship,
        city: values.city,
        hourlyRate: values.hourlyRate,
        hideHourlyRate: values.hideHourlyRate,
        jobDescription: values.jobDescription,
        jobDescriptionResponsibility: values.jobDescriptionResponsibility,
        jobDescriptionRequirements: values.jobDescriptionRequirements,
        companyName: values.companyName,
        companyWebsite: values.companyWebsite,
        companyFacebook: values.companyFacebook,
        companyDescription: values.companyDescription,
        package: values.package,
        acceptanceTerms: values.acceptanceTerms,
        acceptancePrivacyPolicy: values.acceptancePrivacyPolicy,
        acceptanceNewsletter: values.acceptanceNewsletter,
      };

      const response = await axios.post(jobUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.data?.redirect) {
        throw new Error('No redirect in response');
      }

      window.location.href = response.data.redirect;

      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      trackJobFormSubmission();
      return true;
    }
  };

  const isPackageSelected = watch('package') != null && selectedPricing != null;

  return (
    <>
      <Center mt="20px" mb="20px">
        <Box
          bg="background.100"
          borderWidth="1px"
          borderRadius="20px"
          borderColor={'#e4e4ed'}
          position={'relative'}
          width="550px"
          m={2}
        >
          <Box position={'relative'}>
            <Box
              position={'absolute'}
              width="200px"
              left="50%"
              top="10px"
              borderRadius={'5px'}
              p="15px"
              backgroundColor="white"
              borderColor={'#e4e4ed'}
              zIndex={4}
              transform="translateX(-50%)"
            >
              <Image src="https://assets-global.website-files.com/659e5bc66e67b3c9d3f8178c/65fc8406c407058d27699fd0_logo.svg" />
            </Box>
            <Box
              h="250px"
              borderTopLeftRadius={'20px'}
              borderTopRightRadius={'20px'}
              bgGradient={['linear(to-tl, #686781 20%, #0061ff 60%)']}
            >
              <AbsoluteCenter>
                <Text
                  fontSize="lg"
                  color="white"
                  fontWeight="600"
                  align="center"
                >
                  Dodaj ogłoszenie
                </Text>
              </AbsoluteCenter>
            </Box>
          </Box>
          <VStack spacing="5" py="4" px="8" mt="30px" maxW={600}>
            <Text>
              Gotowy do opublikowania ogłoszenia dla swojej firmy? Wybierz
              poniżej rodzaj ogłoszenia i wypełnij wszystkie informacje o pracy.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box position="relative" paddingY={5} mt={10}>
                <Divider />
                <AbsoluteCenter bg="white" px="4" textAlign="center">
                  1. Informacje o Tobie
                </AbsoluteCenter>
              </Box>
              <Box mb={10}>
                <Text fontSize="sm" align="center" fontStyle="italic">
                  Te informacje są widoczne tylko dla administratorów serwisu
                </Text>
              </Box>

              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel htmlFor="firstName" color="text.500" mb="20px">
                  Imię
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="firstName"
                  placeholder="Wpisz swoje imię"
                  {...register('firstName', {
                    required: 'To pole jest wymagane',
                  })}
                  bg="white"
                />
                <FormErrorMessage color="red.500">
                  {errors.firstName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel
                  htmlFor="lastName"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Nazwisko
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="lastName"
                  placeholder="Wpisz swoje nazwisko"
                  {...register('lastName', {
                    required: 'To pole jest wymagane',
                  })}
                />
                <FormErrorMessage>
                  {errors.lastName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" color="text.500" mb="10px" mt="20px">
                  Email
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="email"
                  placeholder="Email"
                  {...register('email', {
                    required: 'To pole jest wymagane',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.phone}>
                <FormLabel htmlFor="phone" color="text.500" mb="10px" mt="20px">
                  Numer telefonu
                  <RedAsterisk />
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>+48</InputLeftAddon>
                  <Input
                    id="phone"
                    placeholder="Wpisz swój numer telefonu"
                    {...register('phone', {
                      required: 'To pole jest wymagane',
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {errors.phone?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <Box position="relative" paddingY={5} mt={10}>
                <Divider />
                <AbsoluteCenter bg="white" px="4" textAlign="center">
                  2. Informacje o ofercie
                </AbsoluteCenter>
              </Box>
              <Box mb={10}>
                <Text fontSize="sm" align="center" fontStyle="italic">
                  Te informacje będą widoczne w Twoim ogłoszeniu
                </Text>
              </Box>

              <FormControl isInvalid={!!errors.specialization}>
                <FormLabel
                  htmlFor="specialization"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Specjalizacja
                  <RedAsterisk />
                </FormLabel>
                <Select
                  placeholder="Wybierz specjalizację"
                  {...register('specialization', {
                    required: 'To pole jest wymagane',
                  })}
                >
                  <option value="Integracja sensoryczna">
                    Integracja sensoryczna
                  </option>
                  <option value="Praca z dziećmi">Praca z dziećmi</option>
                  <option value="Psychoterapia">Psychoterapia</option>
                  <option value="Psychologia kliniczna">
                    Psychologia kliniczna
                  </option>
                  <option value="ACT">ACT</option>
                  <option value="Różne nurty">Różne nurty</option>
                  <option value="Egzystencjalna">Egzystencjalna</option>
                  <option value="Terapia schematów">Terapia schematów</option>
                  <option value="Poznawczo-behawioralna">
                    Poznawczo-behawioralna
                  </option>
                  <option value="Systemowa">Systemowa</option>
                  <option value="Psychodynamika">Psychodynamika</option>
                  <option value="Psychoanaliza">Psychoanaliza</option>
                  <option value="Poznawcza">Poznawcza</option>
                  <option value="Humanistyczna">Humanistyczna</option>
                  <option value="Behavioralizm">Behavioralizm</option>
                  <option value="Integracyjna">Integracyjna</option>
                  <option value="Mindfulness i terapie oparte na uważności">
                    Mindfulness i terapie oparte na uważności
                  </option>
                  <option value="Gestalt">Gestalt</option>
                </Select>
                <FormErrorMessage>
                  {errors.specialization?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.experience}>
                <FormLabel
                  htmlFor="experience"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Doświadczenie
                  <RedAsterisk />
                </FormLabel>
                <Select
                  placeholder="Wybierz doświadczenie"
                  {...register('experience', {
                    required: 'To pole jest wymagane',
                  })}
                >
                  <option value="10 lat +">10 lat +</option>
                  <option value="5 - 10 lat">5 - 10 lat</option>
                  <option value="Staż">Staż</option>
                  <option value="1 - 2 lata">1 - 2 lata</option>
                  <option value="2 - 5 lat">2 - 5 lat</option>
                </Select>
                <FormErrorMessage>
                  {errors.experience?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.voivodeship}>
                <FormLabel
                  htmlFor="voivodeship"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Województwo
                  <RedAsterisk />
                </FormLabel>
                <Select
                  placeholder="Wybierz województwo"
                  {...register('voivodeship', {
                    required: 'To pole jest wymagane',
                  })}
                >
                  <option value="Zachodniopomorskie">Zachodniopomorskie</option>
                  <option value="Dolnośląskie">Dolnośląskie</option>
                  <option value="Kujawsko-Pomorskie">Kujawsko-Pomorskie</option>
                  <option value="Lubelskie">Lubelskie</option>
                  <option value="Lubuskie">Lubuskie</option>
                  <option value="Łódzkie">Łódzkie</option>
                  <option value="Małopolskie">Małopolskie</option>
                  <option value="Mazowieckie">Mazowieckie</option>
                  <option value="Opolskie">Opolskie</option>
                  <option value="Podkarpackie">Podkarpackie</option>
                  <option value="Podlaskie">Podlaskie</option>
                  <option value="Pomorskie">Pomorskie</option>
                  <option value="Śląskie">Śląskie</option>
                  <option value="Świętokrzyskie">Świętokrzyskie</option>
                  <option value="Warmińsko-Mazurskie">
                    Warmińsko-Mazurskie
                  </option>
                  <option value="Wielkopolskie">Wielkopolskie</option>
                  <option value="Online">Online</option>
                </Select>
                <FormErrorMessage>
                  {errors.voivodeship?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.city}>
                <FormLabel htmlFor="city" color="text.500" mb="10px" mt="20px">
                  Miasto
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="city"
                  placeholder="Wpisz nazwę miasta"
                  {...register('city', {
                    required: 'To pole jest wymagane',
                  })}
                />
                <FormErrorMessage>
                  {errors.city?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.hourlyRate}>
                <FormLabel
                  htmlFor="hourlyRate"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Stawka godzinowa
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="hourlyRate"
                  placeholder="Wpisz stawkę"
                  {...register('hourlyRate', {
                    required: 'To pole jest wymagane',
                  })}
                />
                <FormErrorMessage>
                  {errors.hourlyRate?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                as="fieldset"
                my={10}
                isInvalid={!!errors.hideHourlyRate}
              >
                <Checkbox
                  {...register('hideHourlyRate', {
                    validate: () => true,
                  })}
                  size="lg"
                  style={{ alignItems: 'flex-start' }}
                >
                  <FormLabel color="text.500">Ukryj stawkę</FormLabel>
                  <Text fontSize="sm">
                    Jeżeli wolisz, możemy ukryć stawkę w ogłoszeniu
                  </Text>
                </Checkbox>
                <FormErrorMessage>
                  {errors.hideHourlyRate?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.jobDescription}>
                <FormLabel
                  htmlFor="jobDescription"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Opis stanowiska
                  <RedAsterisk />
                </FormLabel>
                <Textarea
                  id="jobDescription"
                  placeholder="Wypełnij informacje"
                  {...register('jobDescription', {
                    required: 'To pole jest wymagane',
                  })}
                  minH={200}
                />
                <FormErrorMessage>
                  {errors.jobDescription?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.jobDescriptionResponsibility}>
                <FormLabel
                  htmlFor="jobDescriptionResponsibility"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Zakres odpowiedzialności
                  <RedAsterisk />
                </FormLabel>

                <Textarea
                  id="jobDescriptionResponsibility"
                  placeholder="Wypełnij informacje"
                  {...register('jobDescriptionResponsibility', {
                    required: 'To pole jest wymagane',
                  })}
                  minH={200}
                />
                <FormErrorMessage>
                  {errors.jobDescriptionResponsibility?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.jobDescriptionRequirements}>
                <FormLabel
                  htmlFor="jobDescriptionRequirements"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Wymagania
                  <RedAsterisk />
                </FormLabel>
                <Textarea
                  id="jobDescriptionRequirements"
                  placeholder="Wypełnij informacje"
                  {...register('jobDescriptionRequirements', {
                    required: 'To pole jest wymagane',
                  })}
                  minH={200}
                />
                <FormErrorMessage>
                  {errors.jobDescriptionRequirements?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <Box position="relative" paddingY={5} mt={10}>
                <Divider />
                <AbsoluteCenter bg="white" px="4" textAlign="center">
                  3. Informacje o firmie
                </AbsoluteCenter>
              </Box>
              <Box mb={10}>
                <Text fontSize="sm" align="center" fontStyle="italic">
                  Te informacje będą widoczne w Twoim ogłoszeniu
                </Text>
              </Box>

              <FormControl isInvalid={!!errors.companyName}>
                <FormLabel
                  htmlFor="companyName"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Nazwa firmy
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="companyName"
                  placeholder="Jak nazywa się Twoja firma?"
                  {...register('companyName', {
                    required: 'To pole jest wymagane',
                  })}
                />
                <FormErrorMessage>
                  {errors.companyName?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.companyWebsite}>
                <FormLabel
                  htmlFor="companyWebsite"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Strona firmy
                  <RedAsterisk />
                </FormLabel>
                <Input
                  id="companyWebsite"
                  placeholder="Adres strony Twojej firmy"
                  {...register('companyWebsite', {
                    required: 'To pole jest wymagane',
                  })}
                />
                <FormErrorMessage>
                  {errors.companyWebsite?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.companyFacebook}>
                <FormLabel
                  htmlFor="companyFacebook"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Facebook
                </FormLabel>
                <Input
                  id="companyFacebook"
                  placeholder="https://facebook.com"
                  {...register('companyFacebook')}
                />
                <FormErrorMessage>
                  {errors.companyFacebook?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.fileLogo}>
                <FormLabel htmlFor="file" color="text.500" mb="10px" mt="20px">
                  Logo firmy
                  <RedAsterisk />
                </FormLabel>

                <FileUpload
                  valueName={'fileLogo'}
                  setError={setError}
                  setValue={setValue}
                  selectedFiles={selectedFilesLogo}
                  setSelectedFiles={setSelectedFilesLogo}
                  accept={'image/*'}
                  register={register('fileLogo', { validate: validateFiles })}
                >
                  <Button leftIcon={<Icon as={FiFile} />} fontWeight="500">
                    Załącz pliki
                  </Button>
                </FileUpload>
                <Text fontSize="sm" py="4">
                  Logo powinno być plikiem PNG lub JPG o wielkości 500x500
                  pikseli.
                </Text>
                <FormErrorMessage>
                  {errors.fileLogo?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.fileCover}>
                <FormLabel htmlFor="file" color="text.500" mb="10px" mt="20px">
                  Cover firmy
                  <RedAsterisk />
                </FormLabel>

                <FileUpload
                  valueName={'fileCover'}
                  setError={setError}
                  setValue={setValue}
                  selectedFiles={selectedFilesCover}
                  setSelectedFiles={setSelectedFilesCover}
                  accept={'image/*'}
                  register={register('fileCover', { validate: validateFiles })}
                >
                  <Button leftIcon={<Icon as={FiFile} />} fontWeight="500">
                    Załącz pliki
                  </Button>
                </FileUpload>
                <Text fontSize="sm" py="4">
                  Cover powinnien być plikiem PNG lub JPG o wielkości 984 x 194
                  pikseli
                </Text>
                <FormErrorMessage>
                  {errors.fileCover?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.companyDescription}>
                <FormLabel
                  htmlFor="companyDescription"
                  color="text.500"
                  mb="10px"
                  mt="20px"
                >
                  Opis firmy
                  <RedAsterisk />
                </FormLabel>
                <Textarea
                  id="companyDescription"
                  placeholder="Napisz opis swojej firmy"
                  {...register('companyDescription', {
                    required: 'To pole jest wymagane',
                  })}
                  minH={200}
                />
                <FormErrorMessage>
                  {errors.companyDescription?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              {isPackageSelected ? null : (
                <>
                  <Box position="relative" paddingY={5} mt={10}>
                    <Divider />
                    <AbsoluteCenter bg="white" px="4" textAlign="center">
                      4. Promowanie
                    </AbsoluteCenter>
                  </Box>
                  <Box mb={10}>
                    <Text fontSize="sm" align="center" fontStyle="italic">
                      Wybierz pakiet, który chcesz kupić
                    </Text>

                    <Center>
                      <Link
                        href="https://psychowork.pl/pricing"
                        target="_blank"
                        isExternal
                        fontSize="sm"
                        textDecor="underline"
                      >
                        Dowiedz się więcej o pakietach na naszej stronie
                      </Link>
                    </Center>
                  </Box>

                  <FormControl isInvalid={!!errors.package}>
                    <FormLabel
                      htmlFor="package"
                      color="text.500"
                      mb="10px"
                      mt="20px"
                    >
                      Pakiet
                      <RedAsterisk />
                    </FormLabel>
                    <Select
                      placeholder="Wybierz pakiet"
                      {...register('package', {
                        required: 'To pole jest wymagane',
                      })}
                    >
                      <option value={jobPackages['free']}>
                        {jobPackages['free']}
                      </option>
                      <option value={jobPackages['basic']}>
                        {jobPackages['basic']}
                      </option>
                      <option value={jobPackages['premium']}>
                        {jobPackages['premium']}
                      </option>
                    </Select>
                    <FormErrorMessage>
                      {errors.package?.message?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}

              <FormControl as="fieldset" mt={10} mb={10}>
                <FormLabel color="text.500">Zgody</FormLabel>
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) => {
                    setValue('acceptanceTerms', !allChecked);
                    setValue('acceptancePrivacyPolicy', !allChecked);
                    setValue('acceptanceNewsletter', !allChecked);
                    trigger('acceptanceTerms');
                    trigger('acceptancePrivacyPolicy');
                  }}
                >
                  Zaznacz wszystkie
                </Checkbox>
                <Stack pl={6} mt={1} spacing={1}>
                  <Checkbox
                    {...register('acceptanceTerms', {
                      validate: validateCheckbox,
                    })}
                    isChecked={watch('acceptanceTerms')}
                    size="sm"
                  >
                    <RedAsterisk /> Akceptuję{' '}
                    <Link
                      href="https://docs.google.com/document/d/e/2PACX-1vTOC9rhO8xyWzNjILxaM7Hv13DizXm70nxfaSz1ZZVeJKyLBEIL_gQtRJBJNUHnZM9eU6HAFV1wMLLq/pub"
                      isExternal
                      target="_blank"
                      color="teal.500"
                    >
                      regulamin
                    </Link>{' '}
                    serwisu PsychoWork.pl
                  </Checkbox>
                  <Text color="red" fontSize="xs">
                    {errors.acceptanceTerms?.message?.toString()}
                  </Text>
                  <Checkbox
                    {...register('acceptancePrivacyPolicy', {
                      validate: validateCheckbox,
                    })}
                    isChecked={watch('acceptancePrivacyPolicy')}
                    size="sm"
                  >
                    <RedAsterisk /> Akceptuję{' '}
                    <Link
                      href="https://docs.google.com/document/d/e/2PACX-1vRUpg9Sc0Vq8TmiTI_ouTcA2v2Zjf1HP29kSY8SqDFHRlfo58HLweGerMjAdDjBYN2ct6I75RxL8lWL/pub"
                      isExternal
                      target="_blank"
                      color="teal.500"
                    >
                      politykę prywatności
                    </Link>{' '}
                    serwisu PsychoWork.pl
                  </Checkbox>
                  <Text color="red" fontSize="xs">
                    {errors.acceptancePrivacyPolicy?.message?.toString()}
                  </Text>
                  <Checkbox
                    {...register('acceptanceNewsletter')}
                    size="sm"
                    isChecked={watch('acceptanceNewsletter')}
                  >
                    Zapisz mnie do newslettera
                  </Checkbox>
                </Stack>
              </FormControl>

              <Center mt="30px" mb="30px">
                <Button
                  mt={4}
                  backgroundColor="#69acdf"
                  fontWeight={'600'}
                  lineHeight={'1.125em'}
                  borderRadius={'1000px'}
                  color="white"
                  isLoading={isSubmitting}
                  loadingText="Wysyłamy Twoje ogłoszenie"
                  type="submit"
                >
                  Dodaj ogłoszenie
                </Button>
              </Center>
            </form>
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
}
