import {
  Box,
  FormErrorMessage,
  IconButton,
  InputGroup,
  List,
  ListItem,
  Progress,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, type ReactNode } from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';
import { FiCheck, FiTrash } from 'react-icons/fi';

export type FileUploadProps = {
  register: UseFormRegisterReturn;
  accept?: string;
  multiple?: boolean;
  children?: ReactNode;
  setValue: Function;
  setError: Function;
  setSelectedFiles: Function;
  selectedFiles: any[];
  valueName: string;
};

export const FileUpload = (props: FileUploadProps) => {
  const {
    register,
    accept,
    multiple,
    children,
    setSelectedFiles,
    selectedFiles,
    setError,
    setValue,
    valueName,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void;
  };

  useEffect(() => {
    if (valueName) {
      // Register the input manually
      setValue(valueName, selectedFiles);
      // Perform validation directly or via useEffect
      if (selectedFiles.length === 0) {
        setError(valueName, {
          type: 'manual',
          message: 'Plik jest wymagany!',
        });
      } else {
        // Clear error if files are selected
        setError(valueName, {});
      }
    }
  }, [selectedFiles, setValue, setError, valueName]);

  const handleClick = () => inputRef.current?.click();

  const handleChange = (event: any) => {
    const files = event.target.files;
    const fileArray: any = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let errorMessage = '';
      if (file.size > 5 * 1024 * 1024) {
        // Example validation: file size less than 1MB
        errorMessage = 'Plik może mieć maksymalnie 5MB';
      }
      fileArray.push({
        name: file.name,
        size: file.size,
        error: errorMessage,
        file,
        contentType: file.contentType,
        progress: 0,
      });
    }
    setSelectedFiles(fileArray);
  };

  const handleDelete = (index: any) => {
    // Remove the file from the selectedFiles array
    const newFiles = selectedFiles.filter(
      (_, fileIndex) => index !== fileIndex
    );
    setSelectedFiles(newFiles);
  };

  return (
    <Box>
      <InputGroup onClick={handleClick}>
        <input
          type={'file'}
          multiple={multiple}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          onChange={handleChange}
        />
        <>{children}</>
      </InputGroup>
      {selectedFiles.length > 0 && (
        <List mt={2} key={'list'}>
          {selectedFiles.map((file: any, index) => (
            <Box key={index + '-' + file.name}>
              <ListItem
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                m="15px"
                maxWidth={'100%'}
              >
                <Text fontSize={'xs'} mr={2} maxWidth="350px">
                  {index + 1}. {file.name} - {(file.size / 1024).toFixed(2)} KB
                </Text>
                <Box>
                  <IconButton
                    aria-label="Delete file"
                    icon={<FiTrash />}
                    size="sm"
                    onClick={() => handleDelete(index)}
                  />
                  {file.progress === 100 ? (
                    <IconButton
                      icon={<FiCheck />}
                      size="sm"
                      isRound={true}
                      _hover={{}}
                      variant="solid"
                      color="white"
                      backgroundColor="#69acdf"
                      aria-label="Done"
                      ml="4"
                    />
                  ) : null}
                </Box>
              </ListItem>
              {file?.error && (
                <Box m="15px">
                  <FormErrorMessage fontSize={'xs'}>
                    {file?.error.toString()}
                  </FormErrorMessage>
                </Box>
              )}
              {file.progress && file.progress !== 0 && file.progress !== 100 ? (
                <Progress hasStripe value={file.progress} />
              ) : null}
            </Box>
          ))}
        </List>
      )}
    </Box>
  );
};
