// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBdfFqtQR8pWUSCEWGAdpT687o9geBQUVs',
  authDomain: 'psychowork-prod.firebaseapp.com',
  projectId: 'psychowork-prod',
  storageBucket: 'psychowork-prod.appspot.com',
  messagingSenderId: '777965933224',
  appId: '1:777965933224:web:0cbd14e9bdbc20e386e89d',
  measurementId: 'G-0FYQJQ7Y8D',
};

// Initialize Firebase
export const infoUrl = `https://info-6u7dvmrbna-lm.a.run.app`;
export const candidateUrl = `https://submit-6u7dvmrbna-lm.a.run.app`;
export const jobUrl = `https://submitjob-6u7dvmrbna-lm.a.run.app`;
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const analytics = getAnalytics(app);
