import {
  Box,
  Divider,
  Grid,
  GridItem,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      mt="auto"
      height="50px"
      pb="30px"
    >
      <Divider mb="20px" />
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        <GridItem w="100%" h="50"></GridItem>
        <GridItem w="100%" h="50">
          <Link
            href="https://www.facebook.com/people/PsychoWorkpl/61557451289508/"
            isExternal
          >
            <Image
              sx={{
                borderRadius: '9999px',
                display: 'inline-block',
                width: '29px', // Adjust the size as needed
                height: '29px', // Adjust the size as needed
                backgroundSize: 'cover', // Ensure the icon covers the element size
                boxShadow: '0 6px 8px rgba(21,116,239,.21)',
              }}
              src="https://assets-global.website-files.com/659e5bc66e67b3c9d3f8178c/659e5bc76e67b3c9d3f81809_icon-1-social-media-job-board-x-template.svg"
            />
          </Link>
        </GridItem>
        <GridItem w="100%" h="50">
          <Text>© 2024 Xonosoft Łukasz Szmidt</Text>
        </GridItem>
        <GridItem w="100%" h="50">
          <Stack direction={'row'} spacing={6}>
            <Link
              href={
                'https://docs.google.com/document/d/e/2PACX-1vRUpg9Sc0Vq8TmiTI_ouTcA2v2Zjf1HP29kSY8SqDFHRlfo58HLweGerMjAdDjBYN2ct6I75RxL8lWL/pub'
              }
            >
              Polityka prywatności
            </Link>
            <Link
              href={
                'https://docs.google.com/document/d/e/2PACX-1vTOC9rhO8xyWzNjILxaM7Hv13DizXm70nxfaSz1ZZVeJKyLBEIL_gQtRJBJNUHnZM9eU6HAFV1wMLLq/pub'
              }
            >
              Regulamin
            </Link>
          </Stack>
        </GridItem>
        <GridItem w="100%" h="50" />
      </Grid>
    </Box>
  );
};

export default Footer;
